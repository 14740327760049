import React from "react";
import "../../styles/testimonial.css";

import Slider from "react-slick";

import ava01 from "../../images/ava-1.jpg";
import ava02 from "../../images/ava-2.jpg";
import ava03 from "../../images/ava-3.jpg";

const Testimonial = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };
  return (
    <section>
      <div className="container">
        <div className="slider__content-top">
          <h6 className="subtitle">Testimonials</h6>
          <h2>
            Trusted by{" "}
            <span className="highlight">all our customers</span>
          </h2>
        </div>
  
        <div className="slider__wrapper">
          <Slider {...settings}>
            <div className="slider__item">
              <p className="description">
                "The team at Daperk revolutionized our online presence! Their creativity and thoroughness led to a remarkable rise in customer engagement and satisfaction."
              </p>
  
              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava01} alt="Customer 1" />
                </div>
  
                <div>
                  <h5 className="customer__name">Carlos Rivera</h5>
                  <p className="description">CEO, Rivera Consulting</p>
                </div>
              </div>
            </div>
  
            <div className="slider__item">
              <p className="description">
                "Partnering with Daperk was transformative for our company! Their web development expertise streamlined our workflow and greatly enhanced customer satisfaction."
              </p>
  
              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava02} alt="Customer 2" />
                </div>
  
                <div>
                  <h5 className="customer__name">Isabella Martinez</h5>
                  <p className="description">Marketing Director</p>
                </div>
              </div>
            </div>
  
            <div className="slider__item">
              <p className="description">
                "I wholeheartedly recommend Daperk! Their commitment and innovation were apparent from the start, resulting in a website that perfectly embodies our brand."
              </p>
  
              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava03} alt="Customer 3" />
                </div>
  
                <div>
                  <h5 className="customer__name">Miguel Garcia</h5>
                  <p className="description">Sr. Product Designer</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
