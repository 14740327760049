import React from "react";
import "../../styles/team.css";

import penaFinance from "../../images/PenaFinance.png";
import sneakers from "../../images/sneakers.png";
import construction from "../../images/construction.png";
import petgrooming from "../../images/petgrooming.jpg";
import doctorappoiment from "../../images/doctorappoiment.png";
import restaurant from "../../images/restaurant.png";

const designs = [
  {
    imgUrl: penaFinance,
    title: "PenaFinance",
    link: "https://www.penafinance.com/",
  },
  {
    imgUrl: sneakers,
    title: "Sneakers",
    link: "https://ecom-web-html-css-java-script.vercel.app/",
  },
  {
    imgUrl: construction,
    title: "construction",
    link: "https://confix.onrender.com/",
  },
  {
    imgUrl: petgrooming,
    title: "Pet Grooming",
    link: "https://drive.google.com/file/d/1Z-ZJVDTquDc1jkYB-7R9LlCMG8BnY8mX/view?usp=sharing",
  },
  {
    imgUrl: doctorappoiment,
    title: "Design Project 5",
    link: "https://prescripto.vercel.app/",
  },
  {
    imgUrl: restaurant,
    title: "Restaurant",
    link: "https://foodies-zone.netlify.app/",
  },
];

const Team = () => {
  return (
    <section className="our__team">
      <div className="container">
        <div className="team__content">
          <h6 className="subtitle">My Designs</h6>
          <h2>
            Showcase of <span className="highlight">my work</span>
          </h2>
        </div>

        <div className="team__wrapper">
          {designs.slice(0, 3).map((item, index) => (
            <div className="team__item" key={index}>
              <div className="team__img">
                <img src={item.imgUrl} alt={item.title} />
              </div>
              <div className="team__details">
                <h4>{item.title}</h4>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  View Project
                </a>
              </div>
            </div>
          ))}
        </div>
        
        <div className="team__wrapper">
          {designs.slice(3, 6).map((item, index) => (
            <div className="team__item" key={index}>
              <div className="team__img">
                <img src={item.imgUrl} alt={item.title} />
              </div>
              <div className="team__details">
                <h4>{item.title}</h4>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  View Project
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
