import React from "react";
import "../../styles/about.css";

import aboutImg from "../../images/about-us.jpg";

const chooseData = [
  {
    icon: "ri-wifi-line",
    title: "Streamlined Working Process",
    desc: "Efficient web app development and design that prioritizes clarity and speed for smooth project progress.",
  },

  {
    icon: "ri-team-line",
    title: "Dedicated Support",
    desc: "Expect direct communication and collaboration, valuing your ideas at every step to realize your vision.",
  },
  {
    icon: "ri-customer-service-2-line",
    title: "24/7 Availability",
    desc: "Support is available whenever you need it, whether for project questions or post-launch assistance.",
  },
];


const About = () => {
  return (
    <section id="about">
      <div className="container">
        <div className="about__wrapper">
          <div className="about__content">
            <h6 className="subtitle">Why choose us</h6>
            <h2>Expertise in Navigating</h2>
            <h2 className="highlight"> Financial Challenges</h2>
            <p className="description about__content-desc">
            eb development solutions that cater to all budgets. My goal is to help clients achieve their digital objectives without compromising quality, even on a tight budget.
            </p>

            <div className="choose__item-wrapper">
              {chooseData.map((item, index) => (
                <div className="choose__us-item" key={index}>
                  <span className="choose__us-icon">
                    <i class={item.icon}></i>
                  </span>
                  <div>
                    <h4 className="choose__us-title">{item.title}</h4>
                    <p className="description">{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="about__img">
            <img src={aboutImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
