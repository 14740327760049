import React from "react";
import "./footer.css";

const quickLinks01 = [
  {
    path: "#service",
    display: "Marketing"
  },
  {
    path: "#service",
    display: "Web Development"
  },
  {
    path: "#service",
    display: "E-Commerce"
  }
];

const quickLinks02 = [
  {
    path: "#contact",
    display: "Pricing"
  },
  {
    path: "#contact",
    display: "Documentation"
  }
];

const quickLinks03 = [
  {
    path: "#about",
    display: "About"
  },
  {
    path: "#blog",
    display: "Blog"
  }
];

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__logo">
            <h2>DAPERK</h2>
            <p className="description">Grow with us</p>
            <p className="small__text description">
              Delivering expert solutions in software, app development, web
              design, digital marketing, and e-commerce to help your business
              succeed
            </p>
          </div>

          <div className="footer__quick-links">
            <h3 className="quick__links-title">Solutions</h3>
            <ul className="quick__links">
              {quickLinks01.map((item, index) => (
                <li className="quick__link-item" key={index}>
                  <a href={item.path}>{item.display}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="footer__quick-links">
            <h3 className="quick__links-title">Support</h3>
            <ul className="quick__links">
              {quickLinks02.map((item, index) => (
                <li className="quick__link-item" key={index}>
                  <a href={item.path}>{item.display}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="footer__quick-links">
            <h3 className="quick__links-title">Company</h3>
            <ul className="quick__links">
              {quickLinks03.map((item, index) => (
                <li className="quick__link-item" key={index}>
                  <a href={item.path}>{item.display}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <p className="copyright">
          Copyright {year}, Daperk, LLC. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
