import React from "react";
import "../../styles/services.css";

const serviceData = [
  {
    icon: "ri-apps-line",
    title: "App Development",
    desc: "Transform your ideas into powerful mobile applications. Our team specializes in creating user-friendly apps that drive engagement and deliver results."
  },

  {
    icon: "ri-code-s-slash-line",
    title: "Web Design",
    desc: "Elevate your online presence with our stunning web designs. We create responsive, aesthetically pleasing websites that captivate your audience."
  },

  {
    icon: "ri-landscape-line",
    title: "Graphics Design",
    desc: "Communicate your brand's message visually with our graphic design services. We craft unique designs that resonate with your target audience."
  },

  {
    icon: "ri-rocket-line",
    title: "Digital Marketing",
    desc: "Boost your brand's visibility with our comprehensive digital marketing strategies. We focus on driving traffic, engagement, and conversions for your business."
  }
];

const Services = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="services__top-content">
          <h6 className="subtitle">Our Services</h6>
          <h2>Save time managing your business with</h2>
          <h2 className="highlight"> our best services</h2>
        </div>

        <div className="service__item-wrapper">
          {serviceData.map((item, index) => (
            <div className="services__item" key={index}>
              <span className="service__icon">
                <i class={item.icon}></i>
              </span>
              <h3 className="service__title">{item.title}</h3>
              <p className="description">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
