import React, { useState } from "react";
import "../../styles/contact.css";

const Contact = () => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");

    const formData = new FormData(event.target);
    formData.append("access_key", "8578e40c-a758-4bc1-a54a-3062f968dbf9");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully!");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <section id="contact">
      <div className="container">
        <div className="contact__wrapper">
          <div className="contact__content">
            <h6 className="subtitle">Let's Connect</h6>
            <h2>
              Get in touch and <span className="highlight">reach out</span>!
            </h2>
          </div>

          <form className="contact__form" onSubmit={onSubmit}>
            <input type="text" name="name" placeholder="Your Name" required />
            <input type="tel" name="tel" placeholder="Phone Number" required />
            <input type="email" name="email" placeholder="Your Email" required />
            <textarea name="message" placeholder="Your Message" required></textarea>
            <button className="secondary__btn contact__btn" type="submit">
              Submit Now
            </button>
          </form>
        </div>
        <span className="result">{result}</span>
      </div>
    </section>
  );
};

export default Contact;
