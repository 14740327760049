import React from "react";
import "../../styles/blog.css";
import daniel from "../../images/daniel.png";


const personalInfo = {
  title: "Daniel Pena",
  desc: "Software Developer and CEO.",
  shortDesc: "Passionate about creating innovative solutions.",
  githubLink: "https://github.com/daperk",
  linkedinLink: "https://www.linkedin.com/in/daniel-pena-90834b255/",
};

const Blog = () => {
  return (
    <section id="blog" className="blog">
      <div className="container">
        <div className="blog__top-content">
          <h6 className="subtitle">My Profile</h6>
          <h2>
            Let's learn more about
            <span className="highlight"> me</span>
          </h2>
        </div>

        <div className="blog__wrapper">
          <div className="blog__item">
            <img src={daniel} alt="Daniel Pena" className="profile-image" />
            <h3>{personalInfo.title}</h3>
            <p className="short-desc">{personalInfo.shortDesc}</p>
            <p className="description blog__desc">{personalInfo.desc}</p>
            <div className="social-links">
              <a href={personalInfo.githubLink} target="_blank" rel="noopener noreferrer">
                <i className="ri-github-line"></i>
              </a>
              <a href={personalInfo.linkedinLink} target="_blank" rel="noopener noreferrer">
                <i className="ri-linkedin-line"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
